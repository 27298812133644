@charset "utf-8";
/* CSS Document */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, font, dialog, 
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  outline: none;
	font: inherit;
	vertical-align: baseline;
	/* */
	outline: none !important;
	font-weight: inherit;
	font-style: inherit;
}

/* Prevent iOS text size adjust after orientation change, without disabling user zoom. */
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height:100%;
    overflow-x: hidden;
    overflow-y: visible;
}

body {
    line-height: 1em;
    font-size:62.5%;
    outline: none ;
    background-color: #000 !important;
    overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, dialog, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}

a img, iframe {
	border: none;
  outline: none;
}

caption, th, td {
	text-align: left;
	font-weight: normal;
}

:focus {
	outline: 0;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, 
q:before, q:after {
	content: '';
	content: none;
}


ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

/* Fixes incorrect placement of numbers in ol's in IE6/7 */
ol { margin-left:2em; }

/* Correct 'inline-block' display not defined in IE 8/9. */
audio, canvas, video {
    display: inline-block;
}

/* Prevent modern browsers from displaying 'audio' without controls.
 * Remove excess height in iOS 5 devices. */
audio:not([controls]) {
    display: none;
    height: 0;
}

/* Address '[hidden]' styling not present in IE 8/9. Hide the 'template' element in IE, Safari, and Firefox < 22. */
[hidden],
template {
    display: none;
}

/* ==========================================================================
   Links
   ========================================================================== */

/* Remove the gray background color from active links in IE 10. */
a {
    background: transparent;
}
/* Address 'outline' inconsistency between Chrome and other browsers. */
a:focus {
    outline: thin dotted;
}
/* Improve readability when focused and also mouse hovered in all browsers. */
a:active, a:hover {
    outline: 0;
}

/* ==========================================================================
   Tables
   ========================================================================== */

/* Remove most spacing between table cells. */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* ==========================================================================
   Forms
   ========================================================================== */



input, textarea {
    padding:0;
}

input, select {
    vertical-align:middle;
}

button {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
}

legend {
    border: 0; /* Correct 'color' not being inherited in IE 8/9. */
    padding: 0; /* Remove padding so people aren't caught out if they zero out fieldsets. */
}

button, input, select, textarea {
    font-family: inherit; /* Correct font family not being inherited in all browsers. */
    font-size: 100%; /* Correct font size not being inherited in all browsers. */
    margin: 0; /* Address margins set differently in Firefox 4+, Safari 5, and Chrome. */
}

/* Address Firefox 4+ setting 'line-height' on 'input' using '!important' in the UA stylesheet. */
button, input {
    line-height: normal;
}

/* Address inconsistent 'text-transform' inheritance for 'button' and 'select'.
   All other form control elements do not inherit 'text-transform' values.
   Correct 'button' style inheritance in Chrome, Safari 5+, and IE 8+.
   Correct 'select' style inheritance in Firefox 4+ and Opera. */
button, select {
    text-transform: none;
}

/* 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native 'audio' and 'video' controls.
   2. Correct inability to style clickable 'input' types in iOS.
   3. Improve usability and consistency of cursor style between image-type 'input' and others. */
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
}

/* Re-set default cursor for disabled elements. */
button[disabled],
html input[disabled] {
    cursor: default;
}

/* 1. Address box sizing set to 'content-box' in IE 8/9/10.
   2. Remove excess padding in IE 8/9/10. */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

/* 1. Address 'appearance' set to 'searchfield' in Safari 5 and Chrome.
   2. Address 'box-sizing' set to 'border-box' in Safari 5 and Chrome (include '-moz' to future-proof). */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
}

/* Remove inner padding and search cancel button in Safari 5 and Chrome on OS X. */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/* Remove inner padding and border in Firefox 4+. */
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto; /* Remove default vertical scrollbar in IE 8/9. */
    vertical-align: top; /* Improve readability and alignment in all browsers. */
    resize: none;
}

input[type=text]::-ms-clear { display: none; }


/*HACK MODEL BOX*/
select {
    border: 1px solid #ccc;
    vertical-align: top;
}
input,
select,
.select,
textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0px;
}
/*END HACK MODEL BOX*/

/* ==========================================================================
   Embedded content
   ========================================================================== */

/* Correct overflow displayed oddly in IE 9. */
svg:not(:root) {
    overflow: hidden;
}

/* ==========================================================================
   Typography
   ========================================================================== */

/* Address styling not present in IE 8/9, Safari 5, and Chrome. */
abbr[title] {
    border-bottom: 1px dotted;
}

b, strong {
    font-weight: bold; /* Address style set to 'bolder' in Firefox 4+, Safari 5, and Chrome. */
}

dfn {
    font-style: italic; /* Address styling not present in Safari 5 and Chrome. */
}

/* Address differences between Firefox and other browsers. */
hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

/* Address styling not present in IE 8/9. */
mark {
    background: #ff0;
    color: #000;
}

/* Correct font family set oddly in Safari 5 and Chrome. */
code,
kbd,
pre,
samp {
    font-size: 1em;
}

/* Improve readability of pre-formatted text in all browsers. */
pre {
    white-space: pre-wrap;
}

/* Set consistent quote types. */
q {
    quotes: "\201C" "\201D" "\2018" "\2019";
}

/* Address inconsistent and variable font size in all browsers. */
small {
    font-size: 80%;
}

/* Prevent 'sub' and 'sup' affecting 'line-height' in all browsers. */

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

