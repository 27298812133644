@import "./variables";

.slider_home
{
    width: 100%;
    margin: 80px auto 0;
    display: flex;
    overflow: hidden;
    margin: auto;
    position: relative;
    margin-bottom: 0px;
    z-index: 1;

    .item_slider{
        position: relative;
        width: 100vw;
        height: 95vh;
        background-color: rgba(13, 14, 19, 0.5803921569);
        background-blend-mode: color;
        position: relative;

        @include mobileGrand
        {
            height: 110vh;
        }

        &.video {
            @include mobileGrand
            {
                height: 100dvh;
            }
            &:before{
                content: '';
                position: absolute;
                display: block;
                width: 100vw;
                height: 95vh;
                background: rgba(13, 14, 19, 0.5803921569);
                @include mobileGrand {
                    height: 110dvh;
                }
            }
            &.activeVideo {
                video{
                    @include tabletMin {
                        display: block;
                        height: 100%;
                    }
                    @include mobileGrand
                    {
                        height: 100dvh;
                    }
                }
                .play_btn {
                    @include tabletMin {
                        display: none;
                    }
                }
            }
            video{
                width: 100%;
                height: 100vh;
                object-fit: cover;

                @include tabletMin {
                    object-fit: cover;
                    height: 100%;
                }
                @include mobileGrand
                {
                    height: 110dvh;
                }
            }
            .play_btn {
                display: none;
                @include tabletMin {
                    content: '';
                    position: relative;
                    display: block;
                    background-image: url('../assets/icon-play.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    width: 50px;
                    height: 50px;
                    top: 40vh;
                    margin: auto;
                    z-index: 1;
                    display: none;
                }
            }
        }
        .description_box{
            position:absolute;
            bottom: 263px;
            left: 0;
            margin-left: 40px;
            z-index: 1;

            @include tabletMin {
                bottom: 137px;
                margin: 0 5%;
                width: auto;
            }

            &.video
            {
                max-width: 532px;
                width: 100%;
                bottom: 70px;
                @include tabletMin {
                    max-width: 100%;
                    margin: 0 5%;
                    width: 90%;
                }
                h3
                {
                    font-size: 32px;
                    display: inline;
                    @include tabletMin {
                        font-size: 20px;
                    }

                }
                p
                {
                    display: inline;
                    width: 100%;
                    font-size: 32px;
                    line-height: 32px;
                    @include tabletMin {
                        font-size: 20px;
                    }
                }
            }
            &.image
            {
                max-width: 768px;
                width: 80%;
                bottom: 70px;
                @include mobileGrand
                {
                    width: 100%;
                    padding: 0 20px;
                    margin: 0;
                }
                h3
                {
                    font-size: 40px;
                    line-height: 44px;
                    text-align: right;
                    width: 300px;
                    float: right;
                    margin-right: 50px;
                    @include mobileGrand
                    {
                        text-align: left;
                        margin-right: 0px;
                        float: left;
                        font-size: 30px;
                        line-height: 34px;
                    }
                }
                p
                {
                    float: left;
                    width: 100%;
                    font-size: 20px;
                    line-height: 22px;
                    width: 530px;
                    @include mobileGrand
                    {
                        text-align: left;
                        margin-right: 0px;
                        float: left;
                        font-size: 18px;
                        line-height: 22px;
                        width: 90%;
                    }
                    &:last-child
                    {
                        float: right;
                        @include mobileGrand
                        {
                            float: left;
                        }
                    }
                    
                }
            }

            h3{
                font-family: 'KumbhSans_Bold';
                margin: 10px 0;
                color: #fff;
            }
            p{
                margin: 10px 0;
                color: #fff;
                width: 25%;
                @include tabletMin {
                    width: 100%;
                }
            }
        }
    }
    .slick-dots{
        width: auto;
        text-align: left;
        bottom: 216px;
        margin: 0 90px;

        @include tabletMin {
            bottom: 97px;
            margin: 0 5%;
        }
    }
    .slick-dots li.slick-active button:before{
        color: #fff;
    }
    .slick-dots li button:before{
        color: #fff;
        font-size: 12px;
    }
    .slick-prev,
    .slick-next{
        left: inherit !important;
        top: unset;
        bottom: 216px;
        z-index: 1;
        width: 26px;
        height: 19px;
        @include tabletMin {
            bottom: 97px;
        }
    }
    .slick-prev{
        right: 260px;
        @include tabletMin {
            right: 15%;
        }

        &:before {
            content: '';
            display: block;
            background-image: url('../assets/arrow-prev.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 100%;
            height: 19px;
        }
    }
    .slick-next{
        right: 194px;
        @include tabletMin {
            right: 5%;
        }
        &:before {
            content: '';
            display: block;
            background-image: url('../assets/arrow-next.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 100%;
            height: 19px;
        }
    }
}