@import "./variables";

.section-1
{
    width: 100%;
    margin: 0 auto !important;
    @include mobileGrand
    {
        margin: 0 auto !important;
    } 
    .row{
        padding: 0px 0;
        margin: 0 auto;
    }
    .vertical-center
    {
        display: flex;
        align-items: flex-end;
        padding: 0 !important;
        justify-content: center;
        &.desktop
        {
            display: block;
            padding: 0 !important;
            @include mobileGrand
            {
                display: none;
            }  
        }
        &.mobile
        {
            display: none;
            @include mobileGrand
            {
                display: block;
            }  
        }
        @include mobileGrand
        {
            display: block;
        }
        @include mobile{
            margin: 0 0 0!important;
            padding: 0;
        }
        .img-section
        {
            width: 100%;
            &.float-right
            {
                float: right;
            }
            @include tabletMax
            {
                margin: 0 auto 0px;
                display: block;
            }  
            @include mobile{
                max-width: unset;
            }
            img
            {
                width: 100%;
                @include tabletMax
                {
                    width: 100%;

                }
                &.float-right
                {
                    float: right;
                }
            }
            .contentVideo
            {
                width: 100%;
                height: 100%;
                background: #000;
                padding: 10px;
                position: relative;
                min-height: 400px;

                iframe
                {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                
                .react-player
                {
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    width: auto !important;
                    height: 100% !important;
                    max-height: 413px !important;
                    video
                    {
                        max-height: 413px;
                    }
                }
            }
        }
        
        
        .section-titles
        {
            font-size: 40px;
            line-height: 44px;
            font-family: 'KumbhSans_Regular';
            color: #fff;
            margin: 0 0 20px;
            width: 60%;
            &.int
            {
                margin: 0 0 10px;
            }
            @include mobileGrand
            {
                width: 100%;
                font-size: 30px;
                line-height: 34px;
            } 
        }
        p
        {   

            font-family: 'KumbhSans_Regular';
            font-size: 18px;
            line-height: 22px;
            color: #fff;
            width: 84%;
            a
            {
                color: #fff;
                text-decoration: underline;
            }
            @include mobileGrand
            {
                margin-bottom: 60px;
                width: 100%;
            } 
        }
    }
}
.section-1 .vertical-center .section-titles
{
    &.skills
    {
        color: #fff;
        
    }
}
.contentInfo
{
    padding: 40px 40px 80px;
    @include tablet {
        padding: 40px;
    }
    @include mobileGrand
    {
        padding: 40px 20px;
    }
}
.section-1 .vertical-center p.infoSkill
{
    color: #fff;
    @include mobileGrand
    {
        margin: 0;
    }
}
.orange
{
    background-color: #EC641F;
    a.link_animation
    {
        background-position: 370px 10px;
        @include tablet
        {
            background-position: 250px 10px;
        }
        @include mobileGrand
        {
            background-position: 290px 10px;
        }
    }
}
.gray
{
    background-color: #464641;
    a.link_animation
    {
        background-position: 190px center;
        @include mobileGrand
        {
            background-position: 150px center;
        }
    }
}
.red
{
    background-color: #EF3B23;
    a.link_animation
    {
        background-position: 250px center;
        @include mobileGrand
        {
            background-position: 190px center;
        }
    }
}
.whiteGray
{
    background-color: #E2E2E2;
    h1,p
    {
        color: #000 !important;
    }
    a.link_animation
    {
        background-position: 200px center;
        background-image: url(../assets/feather-arrow-right-black.png);
        @include mobileGrand
        {
            background-position: 170px center;
        }
    }
}
.contact_form {
    display: block;
    width: 100%;
    background: #000000EB;
    &.active {
        display: flex;
        align-items: flex-end;
        @include tabletMin {
            min-height: 100vh;
            overflow-y: scroll;
            overflow-x: hidden;
            flex-direction: column;
        }
    }
    .container_form {
        max-width: 1440px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        margin-top: 40px;
        @include tabletMin {
            position: relative;
            margin-top: 92px;
            margin-bottom: 87px;
            height: auto;
        }
        @include mobileGrand
        {
            margin-top: 0px;
        }
        .title {
            font-family: 'KumbhSans_Bold';
            color: #FFFFFF;
            font-size: 39px;
            line-height: 100%;
            margin-bottom: 40px;
            margin-left: 40px;
            font-size: 24px;
            line-height: 28px;
            @include mobileGrand
            {
                font-size: 18px;
                line-height: 22px;
                width: calc(100% - 30px);
                margin: 40px auto;
            }
            p
            {
                font-family: 'KumbhSans_Regular';
            }
        }
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            padding: 0 20px;
            &.error {
                .instruction{
                    color: #D82952;
                }
            }
            .field_box {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 11px 20px;
                &.half {
                    width: 50%;
                    @include mobilePlus {
                        width: 100%;
                    }
                }
                label {
                    font-family: 'KumbhSans_Regular';
                    color: #FFFFFF;
                    font-size: 14px;
                }
                input, textarea {
                    padding: 10px;
                    background-color: transparent;
                    border: 0 solid transparent;
                    border-bottom: 1px solid #fff;
                    font-size: 14px;
                    color: #FFF;
                    &.error {
                        border-bottom: 1px solid #D82952;
                    }
                }
                input {
                    height: 35px;
                }
                textarea {
                    resize: none;
                }
            }
            .instruction{
                font-family: 'KumbhSans_Regular';
                color: #FFFFFF;
                font-size: 14px;
                margin: 9px 20px;
            }
            .success {
                display: block;
                font-family: 'KumbhSans_Regular';
                color: #FFFFFF;
                font-size: 14px;
                text-align: center;
                transition-timing-function: ease;
                transition-duration: .4s;
                transition-property: opacity,transform;
                opacity: 0;
                &.active {
                    opacity: 1;
                }
            }
            button {
                display: flex;
                background: none;
                color: #FFF;
                font-size: 16px;
                border: 0px;
                margin: 7px 20px;
                padding-right: 35px;
                opacity: 1;
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    background-image: url('../assets/feather-arrow-right.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    width: 21px;
                    height: 21px;
                    right: 20px;
                }
                &:disabled {
                    opacity: 0.27;
                }
            }
        }
    }
}


.boxAwards
{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto !important;
    color: #FFF;
    padding: 100px 0 50px;
    @include mobileGrand
    {
        padding: 50px 0;
    }

    h2
    {
        font-family: 'KumbhSans_Bold';
        color: #FFFFFF;
        font-size: 40px;
        line-height: 42px;
        line-height: 100%;
        margin-bottom: 80px;
        margin-left: 30px;
        @include mobileGrand
        {
            font-size: 30px;
            line-height: 32px;
        }
    }
    .boxAwardsInfo
    {
        width: 80%;
        margin: 0 auto;
        .boxAwardsInfoImage
        {
            padding: 0 0 50px 70px;
            position: relative;
            h3
            {
                font-family: 'KumbhSans_Bold';
            }
            img
            {
                position: absolute;
                left: 0;
                top: -10px;
            }
            .col-sm-12
            {
                margin-top: 10px;
                font-family: 'KumbhSans_Bold';
                text-transform: uppercase;
                span
                {
                    text-transform: initial;
                    display: block;
                    font-family: 'KumbhSans_Regular';
                }
            }
        }
    }
}

.boxBot
{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto !important;
    color: #FFF;
    padding: 0px 0;
    background-image: url(../assets/trump.png);
    background-repeat: no-repeat;
    background-position: 20% center;
    display: block;
    overflow: hidden;
    height: 700px;
    @include mobileGrand
    {
        height: auto;
        background-position: center 100px;
        background-size: contain;
    }
    h2
    {
        font-family: 'KumbhSans_Bold';
        color: #FFFFFF;
        font-size: 40px;
        line-height: 42px;
        line-height: 100%;
        margin-bottom: 140px;
        margin-left: 40px;
        width: 100%;
        max-width: 700px;
        @include mobileGrand
        {
            font-size: 30px;
            line-height: 32px;
            margin: 0 auto 60px;
            width: calc(100% - 40px);
        }
    }
    .boxBotInfo
    {
        width: 100%;
        max-width: 480px;
        float: right;
        margin-right: 30px;
        @include tablet
        {
            max-width: 100%;
            float: none;
            margin: 55% auto 60px;
            width: calc(100% - 40px);
        }
        h3
        {
            font-family: 'KumbhSans_Bold';
            color: #FFFFFF;
            font-size: 24px;
            line-height: 30px;
            line-height: 100%;
            margin-bottom: 20px;
            width: 100%;
            max-width: 700px;
        }
        p
        {
            font-size: 18px;
            line-height: 22px;
        }
    }
}
.container_box
{
    position: relative;
    &::after
    {
        top: 0;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #00000021 0%,rgba(0,0,0,0) 100%);

    }

}
#contactUS
{
    scroll-margin-top: 175px;
    @include tablet
    {
        scroll-margin-top: 100px;  
    }
}