@import "./variables";

.bannerTop
{
    margin: 0px auto 0;
    display: block;
    width: 100%;
    height: 545px;
    background-size: cover;
    background-position: center;
    position: relative;
    font-family: 'KumbhSans_Regular';
    background-color: #000;
    &::before
    {
        content: '';
        width: 100%;
        position: absolute;
        top: -80px;
        left: 0;
        background-color: #000;
        height: 80px;
    }
    @include tabletMax
    {
        height: 271px;
        background-position: top center;
    }
    @include mobileGrand
    {
        margin-bottom: 12px;
    }
    img
    {
        width: 285px;
        position: absolute;
        margin: 254px 0;
        left: 16%;
        @include tabletMax
        {
            width: 219px;
            left: 0;
            right: 0;
            margin: 129px auto 0;
        }
        &.gatoradeLogo
        {
            width: 192px;
            margin: 215px 0;
            @include tabletMax
            {
                width: 101px;
                left: 0;
                right: 0;
                margin: 128px auto 0;
            }
        }
        &.toyotaLogo
        {
            width: 192px;
            margin: 223px 0;
            @include tabletMax
            {
                width: 93px;
                left: 0;
                right: 0;
                margin: 128px auto 0;
            }
        }
        &.skittles
        {
            width: 250px;
            margin: 237px 0;
            @include tabletMax
            {
                width: 150px;
                left: 0;
                right: 0;
                margin: 128px auto 0;
            }
        }
        &.netflix
        {
            width: 183px;
            margin: 270px 0;
            @include tabletMax
            {
                width: 219px;
                left: 0;
                right: 0;
                margin: 138px auto 0;
            }
        }
    }
}