@import "./variables";

div
{
    width: 100%;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-25px);}
	60% {transform: translateY(-15px);}
}

.description_home
{
    max-width: 1024px;
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    font-size: 39px;
    font-family: 'KumbhSans_Bold';
    color: #C4C4C4;
    line-height: 100%;
    margin: 40px auto;
    @include tabletMax
    {
        font-size: 30px;
        line-height: 32px;
        color: #FFFFFF;
        padding: 0 5%;
        margin: 30px auto;
    }
}
.container.special
{
    max-width: initial;
    padding: 0;
}
a.link_animation
{
    border-radius: 8px;
    font-size: 16px;
    color: #fff;
    width: 100%;
    display: block;
    text-align: left;
    text-decoration: none;
    line-height: 35px;
    background-image: url(../assets/feather-arrow-right.png);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 230px center;
    transition:all .2s ease-out;
    &:hover
    {
        opacity: 0.6;
        background-size: 20px;
    }
    @include tabletMax
    {
        margin: 0 auto;
    }
    @include mobileGrand {
        margin: 0;
        padding: 0;
    }
}