@import "./variables";


.Home
{
    background: #000;
    font-family: 'KumbhSans_Regular';
    .mouse_scroll
    {
        position: fixed;
        bottom: 50px;
        right: 0;
        height: 0;
        margin: 0 auto;
        z-index: 1000;
        width: 32px;
        height: 32px;
        left: 0;
        transform: translateX(-50%);
        animation: bounce2 2s ease infinite;
        @include tabletMax
        {
            display: none;
        }
    }
}

.rowInfo
{
    display: flex;
    position: relative;
    align-items: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 96vh;
    margin: 80px auto 0;
    vertical-align: middle;
    font-family: 'KumbhSans_Regular';
    &.right-cont {
        justify-content: flex-end;
    }
    &.left-cont {
        justify-content: flex-start;
    }
    &.cutInfo
    {
        margin: -8vh auto 0;
        height: 88vh;
        @include tabletMax
        {
            height: auto;
        }
    }
    @include tabletMax
    {
        height: auto;
    }
    @include tabletMax
    {
        background-position: center top;
    }

    .dimesionsSetions
    {
        width: 100%;
        height: 100%;
    }
    .info-cont
    {
        width: 100%;
        max-width: 455px;
        margin: 0 190px;
        z-index: 1;
        position: relative;
        @include tabletMax
        {
            float: none !important;
            display: block;
            margin: 100px auto !important;
            max-width: 334px;
        }
        img
        {
            display: block;
            @include tabletMax
            {
                margin: 0 auto;
            }
            &.miller
            {
                width: 285px;
                @include tabletMax
                {
                    width: 219px;
                }
            }
            &.gatorade
            {
                width: 150px;
                @include tabletMax
                {
                    width: 84px;
                }
            }
            &.toyota
            {
                width: 120px;
                @include tabletMax
                {
                    width: 76px;
                }
            }
            &.skittles
            {
                width: 164px;
                @include tabletMax
                {
                    width: 116px;
                }
            }
            &.netflix
            {
                width: 227px;
                @include tabletMax
                {
                    width: 115px;
                }
            }
        }
        p
        {
            margin: 40px 0;
            font-size: 18px;
            line-height: 26px;
            color: #fff;
            display: block;
            @include tabletMax
            {
                text-align: center;
            }
        }
        a
        {
            border-radius: 8px;
            font-size: 16px;
            color: #fff;
            width: 153px;
            height: 41px;
            display: block;
            text-align: left;
            text-decoration: none;
            line-height: 35px;
            background-image: url(../assets/feather-arrow-right.png);
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: 90% center;
            transition:all .2s ease-out;
            &:hover
            {
                opacity: 0.6;
                background-size: 20px;
                background-position: 95% center;
            }
            @include tabletMax
            {
                margin: 0 auto;
            }
        }
    }
    .BgMobile
    {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: #0d0e1394;
        z-index: 0;
        display: none;
        @include tabletMax
        {
            display: block;
        }
    }
}
