@import "./variables";

footer
{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 80px 40px 40px 40px;
    height: auto;
    overflow: hidden;
    background: #000000;
    justify-content: center;
    position: relative;
    justify-content: space-between;
    margin-top: 20px;
    @include tabletMin {
        flex-direction: column;
    }
    @include mobileGrand
    {
        justify-content: center;
        padding: 80px 30px 20px 30px;
    } 
    .box_aliance
    {
        display: block;
        width: 100%;
        position: absolute;
        left: 40px;
        top: 0;
        @include mobileGrand
        {
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 290px;
        }
        img
        {
            width: 79px;
            float: left;
        }
        p
        {
            float: left;
            color: #fff;
            margin: 5px 5px 5px 10px;
        }
    }
    .contact
    {
        float: left;
        width: 70%;
        @include mobileGrand
        {
            float: none;
            display: block;
            text-align: center;
            margin: 0 auto 20px;
        }
        .box_contact
        {
            width: 180px;
            float: left;
            color: #fff;
            @include mobileGrand
            {
                float: none;
                display: block;
                text-align: center;
                margin: 0 auto 20px;
            }
            strong {
                font-family: 'KumbhSans_Bold';
            }
            a
            {
                display: block;
                color: #fff;
                font-size: 12px;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .social
    {
        display: flex;
        align-items: center;
        // float: right;
        width: auto;
        @include mobileGrand
        {
            float: none;
            display: block;
            text-align: center;
            margin: 0 auto;
        }
        @include mobile {
            margin: 0;
            text-align: left;
        }
    }
    span
    {
        float: left;
        margin: 0;
        color: #fff;
        font-size: 14px;
        font-family: 'KumbhSans_Regular';
        @include mobileGrand
        {
            float: none;
            display: block;
            text-align: center;
        }
    }
    ul
    {
        padding: 0px;
        list-style: none;
        display: inline-block;
        float: left;
        min-width: 200px;
        margin: 0 10px 0 0;
        @include mobileGrand
        {
            float: none;
            display: block;
            text-align: center;
            width: 120px;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        li
        {
            display: inline-block;
            margin: 0 15px;
            transition:all .2s ease-out;
            &:hover
            {
                opacity: 0.6;
                background-size: 20px;
                background-position: 95% center;
            }
        }
    }
}