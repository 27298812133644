@import "./variables";

@keyframes logo 
{
    from {
        width: 29px;
    }
    to {
        width: 270px;
    }
}
header
{
    margin: 0 auto;
    display: block;
    height: 93px;
    width: 100%;
    z-index: 2;
    position: fixed;
    top: 0;
    a.logo
    {
        width: 270px;
        display: block;
        height: 93px;
        margin: 36px 0 0 32px;
        overflow: hidden;
        animation-name: logo;
        animation-duration: 1s;
        float: left;
        position: absolute;
        img
        {
            width: 270px;
            height: auto;
            float: left;
            margin: 0 0 0 0;
            transition:all .2s ease-out;
            @include tablet
            {
                width: 100px;
            }
    
            &:hover
            {
                opacity: 0.7;
            }
        }
        @include tablet
        {
            width: 100px;
            margin: 22px 0 0 20px;
        }
    }
    @include tablet
    {
        width: 100%;
        float: left;
        margin: 0 0 0 0;
        height: auto;
        transition:all .2s ease-out;
    }
    &.active
    {
        span.open
        {
            display: none;
        }
        span.closed
        {
            display: block;
            position: absolute;
            right: 30px;
            top: 30px;
            background-image: url(../assets/cta-close.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 25px;
            height: 25px;
        }
        @include tablet
        {
            .menu_mobile
            {
                position: relative;
                display: flex;
                flex-direction: column;
                transition:all .2s ease-out;
            }
        }
    }
    .menu_box
    {
        float: right;
        width: calc(100% - 182px);
        margin-top: 30px;
        @include tablet
        {
            margin-top: 0px;
            width: 100%;
        }
    }
    nav
    {   
        display: flex;
        justify-content: flex-end;
        width:100%;
        padding-right: 30px;
        transition:all .2s ease-out;
        &:after
        {
            content: '';
            display: table;
            clear: both;
        }
        @include tablet
        {
            margin: 0;
            padding-right: 0px;
        }
        label span
        {
            float:right;
            &.closed
            {
                display: none;
            }
            &.open
            {
                display: block;
            }
        }
        input[type=checkbox]
        {
            display: none;
        }
        ul
        {
            display: flex;
            justify-content: flex-end;
            padding: 0;
            margin: 0;
            list-style: none;
            position: relative;
            transition: all 0.2s ease-out;
            width: 50%;
            height: 80px;

            li
            {
                margin: 0px;
                width: auto;
                height: 80px;
                vertical-align: top;
                padding: 0 32px;
                font-family: 'KumbhSans_Bold';
                font-size: 24px;
                color: #fff;
                display: block;
                text-align: left;
                float: left;
                text-decoration: none;
                line-height: 80px;
                transition: all 0.2s ease-out;
                font-weight: 600;
                text-transform: uppercase;
                &:hover, &.active
                {
                    transition:all .2s ease-out;
                    cursor: pointer;
                    opacity: 0.6;
                    background-size: 20px;
                    background-position: 95% center;
                    
                }
                @include tablet
                {
                    margin-top: 5px;
                    font-size: 14px;
                }
                a
                {
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    &:hover
                    {
                        transition:all .2s ease-out;
                        cursor: pointer;
                        color: #fff;
                        opacity: 0.6;
                        background-size: 20px;
                        background-position: 95% center;
                    }
                }
            }
        }
        
    }
    .menu_mobile{
        position: absolute;
        display: none;
        width: 100%;
        height: 100vh;
        background-color: #000;
        ul{
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0 auto;
            margin-top: 100px;
            li{
                background-image: url(../assets/feather-arrow-right.png);
                background-repeat: no-repeat;
                background-size: 12px;
                background-position: 90% center;
                font-size: 18px;
                min-width: 322px;
                margin: 0 auto;
                padding: 14px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                &:hover, &.active
                {
                    transition:all .2s ease-out;
                    cursor: pointer;
                    opacity: 0.6;
                    background-size: 20px;
                    background-position: 95% center;
                }
                a
                {
                    color: #fff;
                    text-decoration: none;
                    height: 27px;
                    width: 100%;
                    &:hover
                    {
                        transition:all .2s ease-out;
                        cursor: pointer;
                        opacity: 0.6;
                        background-size: 20px;
                        background-position: 95% center;
                    }
                }
            }
        }
        .social{
            position: absolute;
            display: flex;
            flex-direction: row;
            bottom: 13%;
            left: 0;
            right: 0;
            margin: auto;
            width: 322px;
            a {
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-color: rgba(13, 14, 19, 0.5803921569);
                background-blend-mode: color;
                margin: 0 25px;
                &.fb{
                    background-image: url(../assets/facebook.svg);
                }
                &.lin{
                    background-image: url(../assets/linkedin.svg);
                }
                &.tw{
                    background-image: url(../assets/twitter.svg);
                }
                &.ins{
                    background-image: url(../assets/instagram.svg);
                }
            }
        }
    }

    
    .toggle,
    [id^=drop]
    {
        display: none;
    }
    .toggle {
        display: none;
    }
    .mobile_logo
    {
        display: none;
        margin: 7px 12px;
        height: 45px;
        position: absolute;
        left: 1%;
        @include tablet
        {
            display: block;
        }
    }
    @include tablet
    {
        .toggle + a {
            display: none;
        }

        .toggle {
            display: none;
            padding: 0 20px;
            color: #FFF;
            font-size: 40px;
            line-height: 76px;
            text-decoration: none;
            border: none;
        }
    }
    
    .contact_form {
        display: block;
        width: 100%;
        height: 300px;
        background: #000000EB;
        &.active {
            display: flex;
            align-items: flex-end;
            @include tabletMin {
                min-height: 100vh;
                overflow-y: scroll;
                overflow-x: hidden;
                flex-direction: column;
            }
        }
        .close {
            content: '';
            position: absolute;
            display: block;
            background-image: url('../assets/cta-close.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 45px;
            height: 45px;
            top: 100px;
            right: 190px;
            z-index: 1;
            &:hover {
                cursor: pointer;
            }
            @include tabletMax {
                right: 20px;
            }
            @include tabletMin {
                top: 20px;
                width: 20px;
                height: 20px;
            }
        }
        .container_form {
            position: absolute;
            max-width: 944px;
            height: 370px;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            @include tabletMin {
                position: relative;
                margin-top: 92px;
                margin-bottom: 87px;
                height: auto;
            }
            .title {
                font-family: 'KumbhSans_Bold';
                color: #FFFFFF;
                font-size: 39px;
                line-height: 100%;
                margin-bottom: 80px;
                margin-left: 20px;
            }
            form {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                &.error {
                    .instruction{
                        color: #D82952;
                    }
                }
                .field_box {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 11px 20px;
                    &.half {
                        width: 50%;
                        @include mobilePlus {
                            width: 100%;
                        }
                    }
                    label {
                        font-family: 'KumbhSans_Regular';
                        color: #FFFFFF;
                        font-size: 12px;
                    }
                    input, textarea {
                        padding: 10px;
                        background-color: transparent;
                        border: 0 solid transparent;
                        border-bottom: 1px solid #fff;
                        font-size: 14px;
                        color: #FFF;
                        &.error {
                            border-bottom: 1px solid #D82952;
                        }
                    }
                    input {
                        height: 35px;
                    }
                    textarea {
                        resize: none;
                    }
                }
                .instruction{
                    font-family: 'KumbhSans_Regular';
                    color: #FFFFFF;
                    font-size: 14px;
                    margin: 9px 20px;
                }
                .success {
                    display: block;
                    font-family: 'KumbhSans_Regular';
                    color: #FFFFFF;
                    font-size: 14px;
                    text-align: center;
                    transition-timing-function: ease;
                    transition-duration: .4s;
                    transition-property: opacity,transform;
                    opacity: 0;
                    &.active {
                        opacity: 1;
                    }
                }
                button {
                    display: flex;
                    background: none;
                    color: #FFF;
                    font-size: 16px;
                    border: 0px;
                    margin: 7px 20px;
                    padding-right: 35px;
                    opacity: 1;
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        background-image: url('../assets/feather-arrow-right.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        width: 21px;
                        height: 21px;
                        right: 20px;
                    }
                    &:disabled {
                        opacity: 0.27;
                    }
                }
            }
        }
        .contact_info{
            display: flex;
            align-items: center;
            @include tabletMin {
                flex-wrap: wrap;
            }
            .box_contact {
                font-family: 'KumbhSans_Regular';
                color: #FFFFFF;
                font-size: 12px;
                margin: 37px 53px;
                @include tabletMin {
                    margin: 15px 20px;
                }
                span {
                    font-family: 'KumbhSans_Bold';
                    font-size: 16px;
                }
                a{
                    color: #FFFFFF;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
